import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import {
  Grid,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Checkbox,
  Switch,
} from '@material-ui/core'
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useDispatch } from 'react-redux'
import { useCustomCombo } from '../../../combos'

import { requests } from '../../../../core/ajax'
import NormalizeForm from './normalize-form.component'
import { DocumentFieldForm } from './document-field-form.component'
import { useConsultContext } from '../../contexts'
import { useTooFarDialog } from '../../dialogs'
import {
  CUSTOMER_FIELDS_CONFIG,
  FORM_BUTTONS_CONFIG,
  REQUIRE_NUMBER,
  APP_PATH,
  APP_TYPE,
  APP_PATH_RULES,
  SCR_NO_CAMPAIGNS_REQUIRED_FIELDS,
  SCR_CAMPAIGNS_REQUIRED_FIELDS,
  SCR_PYMES_CAMPAIGNS_REQUIRED_FIELDS,
  SCR_GG_AND_CC_CAMPAIGNS_REQUIRED_FIELDS,
  GASP_UPLOAD_BUTTONS_CONFIG,
} from '../../constants'
import { getTypeOfDocument } from '../../../../core/utils/idDocValidation'
import { distHaversines } from '../../../../core/maps/haversine.func'
import {
  AcceptationDialog,
  ThemeButton,
  TextInput,
  SelectInput,
  MapPicker,
  PanoramaPicker,
  RadioInput,
  useModalContext,
} from '../../../../ui'
import { DOCUMENT_ATTACHMENT_CONFIG } from '../../../documents'
import { useCombos, setCombo } from '../../../combos'
import { CertificateServices } from '../certificate-services'
import {
  getGestorGC,
  getTarifaByConsumo,
  getConsumoByPotencia,
} from '../../calls/consult-information.calls'

const MAX_DISTANCE_ALLOWED = 0.02 //In km.

export const ConsultCustomerForm = () => {
  const intl = useIntl()
  const {
    isLoading,
    customerForm,
    changeCustomerForm,
    changeCustomerFormArray,
    createApplication,
    selectedHome,
    reset,
    consultData,
    prepareCertificate,
    changeIsContratable,
    isContratable,
    prepareCertificateUnlockedPath,
    certificateForm,
  } = useConsultContext()
  const installerId = useSelector((state) => state.global.user_id)
  const installerEmail = useSelector((state) => state.global.email)
  const { get } = requests
  const reduxDispatch = useDispatch()
  const isGc =
    customerForm['uso'] === 'gran_consumo'
  const isPotenciaAndConsumoCorrect =
    customerForm['uso'] !== 'gran_consumo' &&
    customerForm['uso'] !== undefined &&
    (customerForm['potencia'] !== '<50' ||
      customerForm['potencia'] !== '50-69' ||
      customerForm['numero_tarifa_aplicada'] === 'RL04' ||
      customerForm['numero_tarifa_aplicada'] === 'RLPS4')
  const handleResetCustomerForm = () => {
    changeCustomerForm({ name: 'uso', value: 'residencial' })
  }
  useCustomCombo(['tarifas'], { cidi: selectedHome.cidi, uso: customerForm.uso })
  const {
    actions: { open },
  } = useModalContext()
  useEffect(() => {
    const comboData = []
    get(`tarifas_consumos/obtenerTarifasConsumosByMunicipioId/${selectedHome.id_municipio}`)
      .then(({ data }) => {
        data.forEach((item) => {
          comboData.push({
            value: item.codigo.concat(` - ${item.consumo}`),
            tarifa_aplicada: item.codigo,
            potencia: item.potenciaKey,
            key: item.consumoKey,
          })
        })
        reduxDispatch(setCombo({ comboId: 'consumo', data: comboData }))
      })
      .catch((error) => {
        console.error(error)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isGc && customerForm.potencia !== '<50' &&
      customerForm.potencia !== '50-69' &&
      customerForm.potencia !== undefined ) {
      const mercadoId = combos.tipo_mercado.data.find(
        (mercado) => mercado.key === selectedHome.mercado
      )?.id

      getGestorGC(mercadoId, selectedHome.cod_postal, customerForm.uso)
        .then((response) => {
          const { nombre, telefono, email } = response
          open({
            Component: AcceptationDialog,
            data: {
              title: intl.formatMessage({ id: 'pages.consult.granconsumo.title' }),
              text: intl.formatMessage(
                { id: 'pages.consult.granconsumo.text' },
                { nombre: nombre, telefono: telefono, email: email }
              ),
              yesText: intl.formatMessage({ id: 'pages.consult.granconsumo.yestext' }),
              yesAction: handleResetCustomerForm,
            },
            type: 'centered',
          })
        })
        .catch(() => {
          open({
            Component: AcceptationDialog,
            data: {
              title: intl.formatMessage({ id: 'pages.consult.granconsumo.title' }),
              text: intl.formatMessage(
                isGc
                  ? { id: 'pages.consult.granconsumo.fail.text' }
                  : { id: 'pages.consult.granconsumo.potencia.consumo.fail.text' }
              ),
              yesText: intl.formatMessage({ id: 'pages.consult.granconsumo.yestext' }),
            },
            type: 'centered',
          })
        })
    } else {
      /*if (
        customerForm.potencia !== '<50' &&
        customerForm.potencia !== '50-69' &&
        customerForm.uso === 'pymes' &&
        customerForm.potencia !== undefined
      ) {
        console.log("SITIO 2")
        console.log(customerForm)
        const mercadoId = combos.tipo_mercado.data.find(
          (mercado) => mercado.key === selectedHome.mercado
        )?.id

        getGestorGC(mercadoId, selectedHome.cod_postal, customerForm.uso)
          .then((response) => {
            const { nombre, telefono, email } = response
            open({
              Component: AcceptationDialog,
              data: {
                title: intl.formatMessage({ id: 'pages.consult.granconsumo.title' }),
                text: intl.formatMessage(
                  { id: 'pages.consult.granconsumo.text' },
                  { nombre: nombre, telefono: telefono, email: email }
                ),
                yesText: intl.formatMessage({ id: 'pages.consult.granconsumo.yestext' }),
                yesAction: handleChangeconsumo,
              },
              type: 'centered',
            })
          })
          .catch(() => {
            open({
              Component: AcceptationDialog,
              data: {
                title: intl.formatMessage({ id: 'pages.consult.granconsumo.title' }),
                text: intl.formatMessage(
                  isGc
                    ? { id: 'pages.consult.granconsumo.fail.text' }
                    : { id: 'pages.consult.granconsumo.potencia.consumo.fail.text' }
                ),
                yesText: intl.formatMessage({ id: 'pages.consult.granconsumo.yestext' }),
              },
              type: 'centered',
            })
          })
      } */
      if (!customerForm.potencia) {
        if (customerForm.uso) {
          getTarifaByConsumo(
            selectedHome.cidi
              ? { params: { uso: customerForm.uso, cidi: selectedHome.cidi } }
              : { params: { uso: customerForm.uso } }
          ).then((response) => {
            setSelectedPotencia(response)
          })
        }
      } else {
        getConsumoByPotencia(
          selectedHome.cidi
            ? { params: { potencia_key: customerForm.potencia, cidi: selectedHome.cidi } }
            : { params: { potencia_key: customerForm.potencia } }
        ).then((response) => {
          reduxDispatch(setCombo({ comboId: 'consumo', data: response }))
          setSelectedConsumo(response)
        })
      }
    }
  }, [customerForm.uso, customerForm.potencia]) // eslint-disable-line react-hooks/exhaustive-deps
  let combos = useCombos([
    'rangos_potencia',
    'rangos_consumo',
    'ubicacion_contador',
    'propiedad_contador',
    'propiedad_irc_armario_regulacion',
    'caso_caldera_centralizada',
    'tarifa_aplicada',
  ])
  const [errorMssg, setErrorMssg] = useState('')
  const [selectedPotencia, setSelectedPotencia] = useState([])
  const [selectedConsumo, setSelectedConsumo] = useState([])
  const flowType = consultData['flowType'] || APP_PATH.NNSAG
  const exteriorAppAlreadyExists = consultData['exterior_pendiente_certificado']
  const flowRules = APP_PATH_RULES[flowType]
  const conditionCheckbox = !flowRules.certificate
  const [selectedAppType, setSelectedAppType] = useState(flowRules.selector[0].id)
  const [check, setCheck] = useState(conditionCheckbox ? false : true)
  const panoramaRef = useRef()
  //Coordinate storage:
  const tooFar = useTooFarDialog()
  const choosesPosition =
    (consultData.automatic_budget && customerForm.pasar_contratable !== true) ||
    process.env.REACT_APP_MOCKS

  const position = {
    lat: customerForm.latitude,
    lng: customerForm.longitude,
  }

  const panoramaPosition = {
    lat: customerForm.sv_latitud,
    lng: customerForm.sv_longitud,
  }
  const pov = {
    heading: customerForm.heading,
    pitch: customerForm.pitch,
  }
  const center = consultData.latitude
    ? {
        lat: consultData.latitude,
        lng: consultData.longitude,
      }
    : null
  const handlePosition = (position) => {
    const dist = distHaversines({ origin: center, target: position })
    if (dist > MAX_DISTANCE_ALLOWED) {
      tooFar(
        () =>
          changeCustomerFormArray({
            latitude: position.lat,
            longitude: position.lng,
          }),
        () => changeCustomerFormArray({})
      )
      //Dirty trick to re-trigger positioning:
      return
    }
    changeCustomerFormArray({
      latitude: position.lat,
      longitude: position.lng,
    })
  }

  const handleChangeconsumo = async () => {
    await changeCustomerForm({ name: 'potencia', value: undefined })
    await changeCustomerForm({ name: 'consumo', value: undefined })
    setSelectedPotencia([])
    setSelectedConsumo([])
  }
  const handlePanoramaPosition = (position) => {
    const dist = distHaversines({ origin: center, target: position })
    if (dist > MAX_DISTANCE_ALLOWED) {
      tooFar(
        () =>
          changeCustomerFormArray({
            sv_width: panoramaRef.current?.getBoundingClientRect().width,
            sv_height: panoramaRef.current?.getBoundingClientRect().height,
            sv_marker_x: position.pixel?.x,
            sv_marker_y: position.pixel?.y,
            sv_latitud: position.lat,
            sv_longitud: position.lng,
          }),
        () => changeCustomerFormArray({})
      )
      return
    }
    changeCustomerFormArray({
      sv_width: panoramaRef.current?.getBoundingClientRect().width,
      sv_height: panoramaRef.current?.getBoundingClientRect().height,
      sv_marker_x: position.pixel?.x,
      sv_marker_y: position.pixel?.y,
      sv_latitud: position.lat,
      sv_longitud: position.lng,
    })
  }
  const setPov = (pov) => {
    changeCustomerFormArray({
      heading: pov.heading,
      pitch: pov.pitch,
      povLat: pov.povLat,
      povLng: pov.povLng,
    })
  }
  const createApplicationRequest = flowRules.certificate
    ? exteriorAppAlreadyExists
      ? prepareCertificateUnlockedPath
      : prepareCertificate
    : createApplication

  const handleToggleFilter = (toogleState) => {
    changeIsContratable(toogleState)
    changeCustomerForm({ name: 'pasar_contratable', value: toogleState })
  }

  const handleSubmit = (e) => {
    let scr_required_fields = []
    if (customerForm.persona === 'J') customerForm.nombre = '.'
    customerForm.applyCampaign
      ? customerForm.uso === 'pymes' || customerForm.mercado === 'PYME'
        ? (scr_required_fields = SCR_PYMES_CAMPAIGNS_REQUIRED_FIELDS)
        : customerForm.uso === 'caldera_centralizada' ||
          customerForm.uso === 'gran_consumo' ||
          customerForm.mercado === 'CC' ||
          customerForm.mercado === 'GC'
        ? (scr_required_fields = SCR_GG_AND_CC_CAMPAIGNS_REQUIRED_FIELDS)
        : (scr_required_fields = SCR_CAMPAIGNS_REQUIRED_FIELDS)
      : (scr_required_fields = SCR_NO_CAMPAIGNS_REQUIRED_FIELDS)
    e.preventDefault()
    if (customerForm['email'] === installerEmail) {
      setErrorMssg('Por favor, indica los datos del cliente, no los tuyos')
      return
    }
    if (
      customerForm['tipo_identificador'] !== 'Passport' &&
      getTypeOfDocument(customerForm['identificador']) !== customerForm['tipo_identificador']
    ) {
      setErrorMssg('El formato del identificador no es correcto')
      return
    }
    if (isGc && isPotenciaAndConsumoCorrect) {
      const mercadoId = combos.tipo_mercado.data.find(
        (mercado) => mercado.key === selectedHome.mercado
      )?.id

      getGestorGC(mercadoId, selectedHome.cod_postal, customerForm.uso)
        .then((response) => {
          const { nombre, telefono, email } = response
          open({
            Component: AcceptationDialog,
            data: {
              title: intl.formatMessage({ id: 'pages.consult.granconsumo.title' }),
              text: intl.formatMessage(
                { id: 'pages.consult.granconsumo.text' },
                { nombre: nombre, telefono: telefono, email: email }
              ),
              yesText: intl.formatMessage({ id: 'pages.consult.granconsumo.yestext' }),
            },
            type: 'centered',
          })
        })
        .catch(() => {
          open({
            Component: AcceptationDialog,
            data: {
              title: intl.formatMessage({ id: 'pages.consult.granconsumo.title' }),
              text: intl.formatMessage(
                isGc
                  ? { id: 'pages.consult.granconsumo.fail.text' }
                  : { id: 'pages.consult.granconsumo.potencia.consumo.fail.text' }
              ),
              yesText: intl.formatMessage({ id: 'pages.consult.granconsumo.yestext' }),
            },
            type: 'centered',
          })
        })

      //trabajar con selectedHome para conseguir los datos del Gestor
    } else {
      switch (selectedAppType) {
        case APP_TYPE.SCR:
          if (
            (!flowRules.certificate &&
              scr_required_fields.filter((el) => !customerForm[el]).length > 0) ||
            (!flowRules.certificate &&
              customerForm['uso'] === 'caldera_centralizada' &&
              !customerForm['caso_caldera_centr'])
          ) {
            console.debug(
              'Uso caldera centralizada caso caldera',
              scr_required_fields.filter((el) => !customerForm[el])
            )
            setErrorMssg('Es obligatorio indicar todos los campos marcados (*)')
            return
          }
          if (
            !flowRules.certificate &&
            REQUIRE_NUMBER.indexOf(customerForm['uso']) !== -1 &&
            !customerForm['potencia'] &&
            !customerForm['consumo']
          ) {
            setErrorMssg('Por favor, indica una potencia o consumo')
            return
          }
          break
        case APP_TYPE.SAG:
          if (!customerForm['SG'] && flowType !== APP_PATH.NNSAG) {
            setErrorMssg('Es obligatorio añadir una Solicitud de gas en fincas')
            return
          } else if (!customerForm['croquis_i'] && flowType !== APP_PATH.NNSAG) {
            setErrorMssg('Es obligatorio añadir un Croquis situación acometida')
            return
          }
          break
        case APP_TYPE.NNE:
        case APP_TYPE.NN:
          if (!customerForm['uso']) {
            setErrorMssg('Es obligatorio indicar el uso del punto de suministro')
            return
          }
          break
        case APP_TYPE.NNI:
          if (!customerForm['uso']) {
            setErrorMssg('Es obligatorio indicar el uso del punto de suministro')
            return
          }
          break
        default:
          console.debug('Error: selectedAppType is not defined')
      }
      setErrorMssg('')
      let documentsToUpload = FORM_BUTTONS_CONFIG[selectedAppType]
        .filter((el) => el.doc)
        .map((el) => el.doc)
      Object.entries(GASP_UPLOAD_BUTTONS_CONFIG).forEach(([key, value]) => {
        if (customerForm[value.doc]?.data) documentsToUpload.push(value.doc)
      })
      createApplicationRequest(
        installerId,
        selectedAppType,
        documentsToUpload
        // FORM_BUTTONS_CONFIG[selectedAppType].filter((el) => el.doc).map((el) => el.doc)
      ).catch((err) => {
        if (err.message) {
          setErrorMssg(err.message)
        }
      })
    }
  }

  const handleCancel = () => {
    reset()
  }

  const handleChange = async (e) => {
    let { name, value, checked } = e.target
    if (name === 'uso') {
      await changeCustomerForm({ name: 'potencia', value: undefined })
      await changeCustomerForm({ name: 'consumo', value: undefined })
      setSelectedPotencia([])
      setSelectedConsumo([])
    }
    //Special case for services field in certificate form
    if (name === 'services') {
      if (value && value.includes('calefaccion')) {
        if (!value.includes('agua_caliente')) {
          value = [...value, 'agua_caliente']
        }
      }
    }
    if (name === 'sin_ubicacion') {
      value = checked
      changeCustomerForm({ name, value })
    } else {
      changeCustomerForm({ name, value })
    }
  }

  const inputProps = {
    formState: customerForm,
    onChange: handleChange,
    intl,
    combos,
    selectedPotencia,
    selectedConsumo,
  }

  return (
    <>
      <Box textAlign="center">
        <Typography variant="h4" color="primary" paragraph>
          {intl.formatMessage({ id: 'pages.consult.title.2' })}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {intl.formatMessage({ id: 'pages.consult.customerSubtitle' })}
        </Typography>
      </Box>
      <Box pl={[0, 0, 5]} pr={[0, 0, 5]}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RadioInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).TIPO_PERSONA }} />
            </Grid>
            {customerForm.persona === 'F' ? (
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).NOMBRE }} />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).APELLIDOS }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).TIPO_IDENTIFICADOR }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).IDENTIFICADOR }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).EMAIL }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).TELEFONO }} />
            </Grid>
          </Grid>
          {!flowRules.certificate ? (
            <Box textAlign="center" mt={3}>
              <Typography variant="h4" color="primary" paragraph>
                {intl.formatMessage({ id: 'pages.consult.title.3' })}
              </Typography>
            </Box>
          ) : null}
          {flowRules.selector.length > 1 ? (
            <>
              <Box mb={2}>
                <FormControl
                  component="fieldset"
                  onChange={(e) => setSelectedAppType(e.target.value)}
                >
                  <RadioGroup row value={selectedAppType}>
                    {flowRules.selector.map(({ id, labelId }) => {
                      return (
                        <FormControlLabel
                          key={id}
                          value={id}
                          control={<Radio size="small" color="primary" />}
                          label={
                            <Typography variant="body1" color="textSecondary">
                              {intl.formatMessage({ id: labelId })}
                            </Typography>
                          }
                        />
                      )
                    })}
                  </RadioGroup>
                </FormControl>
              </Box>
            </>
          ) : null}
          {!flowRules.certificate ? (
            <Grid container>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {/* <GeoLinks /> */}
                  <NormalizeForm
                    {...{
                      ...inputProps,
                      fieldList: CUSTOMER_FIELDS_CONFIG(inputProps),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CertificateServices
                    {...{
                      formState: {
                        ...customerForm,
                        ...selectedHome,
                        ...certificateForm,
                        origen: 'instalador',
                      },
                      combos,
                      handleChange,
                      showErrors: false,
                      viewCampanas: true,
                    }}
                  />
                </Grid>
                <DocumentFieldForm
                  {...{
                    ...inputProps,
                    flowType,
                    fieldList: CUSTOMER_FIELDS_CONFIG(inputProps),
                    docList: DOCUMENT_ATTACHMENT_CONFIG,
                    config: FORM_BUTTONS_CONFIG[selectedAppType],
                  }}
                />
              </Grid>

              {consultData.estado_cups !== 'CONTRATABLE' && !APP_PATH_RULES[flowType].normalize ? (
                <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                  <FormControlLabel
                    variant="outlined"
                    label="Ya esta conectada, pasar a contratable"
                    control={
                      <Switch
                        {...{
                          onClick: ({ target: { checked } }) => handleToggleFilter(checked),
                          checked: isContratable,
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                </Box>
              ) : null}

              {choosesPosition ? (
                <Grid item xs={12}>
                  <Box display="flex" mt={3} mb={2} flexWrap="wrap">
                    <Box mr={2}>
                      <Typography variant="h5" color="textPrimary" paragraph>
                        {intl.formatMessage({ id: 'pages.consult.title.4' })}
                      </Typography>
                      <div
                        style={{
                          position: 'relative',
                          height: '25em',
                          width: '523px',
                          top: 0,
                        }}
                      >
                        <MapPicker {...{ position, setPosition: handlePosition, center }} />
                      </div>
                    </Box>

                    <Box>
                      <Typography variant="h5" color="textPrimary" paragraph>
                        {intl.formatMessage({ id: 'pages.consult.title.5' })}
                      </Typography>
                      <div
                        ref={panoramaRef}
                        style={{
                          position: 'relative',
                          height: '25em',
                          width: '524px',
                        }}
                      >
                        <PanoramaPicker
                          {...{
                            position: panoramaPosition,
                            setPosition: handlePanoramaPosition,
                            center,
                            ...pov,
                            setPov,
                          }}
                        />
                      </div>
                    </Box>
                  </Box>
                  <Alert severity="warning">
                    {intl.formatMessage({ id: 'pages.consult.description.1' })}
                  </Alert>
                </Grid>
              ) : null}
              {conditionCheckbox ? (
                <Box display="flex" alignItems="center" mt={2}>
                  <Checkbox
                    color="primary"
                    size="small"
                    required
                    name="compromise"
                    onClick={() => {
                      setCheck((check) => !check)
                    }}
                  />
                  <Typography variant="body2">
                    {intl.formatMessage({ id: 'pages.consult.disclaimer' })}
                  </Typography>
                </Box>
              ) : null}
              <Grid item mt={2} xs={12}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).OBSERVACIONES }} />
              </Grid>
            </Grid>
          ) : null}
          {errorMssg && (
            <Box mt={2} mb={1}>
              <Alert severity="error">{errorMssg}</Alert>
            </Box>
          )}
          <Box display="flex" flexDirection="column" textAlign="center" mt={3}>
            <ThemeButton
              color="secondary"
              labelM="lg"
              type="submit"
              loading={isLoading}
              disabled={!check}
            >
              {intl.formatMessage({
                id: flowRules.certificate
                  ? 'pages.consult.customerButtons.goNext'
                  : 'global.accept',
              })}
            </ThemeButton>
            {flowRules.certificate || flowRules.normalize || !choosesPosition ? null : (
              <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                <Checkbox
                  color="primary"
                  size="small"
                  name="sin_ubicacion"
                  onClick={(e) => {
                    handleChange(e)
                  }}
                />
                <Typography variant="body2">
                  {intl.formatMessage({ id: 'pages.consult.sinubicacion' })}
                </Typography>
              </Box>
            )}
            <ThemeButton
              variant="text"
              color="inherit"
              labelM="lg"
              style={{ marginTop: 15 }}
              onClick={handleCancel}
            >
              {intl.formatMessage({
                id: 'global.cancel',
              })}
            </ThemeButton>
          </Box>
        </form>
      </Box>
      <Backdrop open={isLoading} style={{ zIndex: 99 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
