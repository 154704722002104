import React, {useEffect, useState} from 'react'
import { useIntl } from 'react-intl'
import {Box, Grid, TextField} from '@material-ui/core'

import { AutocompleteInput, ThemeButton } from '../../../../ui'
import { ADDRESS_FIELDS_CONFIG, ADDRESS_FORM_FIELDS } from './address-form.constants'
import { useAddressCalls } from '../../calls'
import {Alert} from "@material-ui/lab";

export const AddressForm = ({ close, onSelect, cups,  isIRC, onCancel, data }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const { getZipMunicipalities, getStreets, getNumbers, getZipMunicipalitiesDerivaciones,getStreetsDerivaciones,getNumbersDerivaciones, getDerivations, getVivienda, validateCupsWithDireccion } = useAddressCalls()

  const [formState, setFormState] = useState({})
  const [townValues, setTownValues] = useState([])
  const [addressValues, setAddressValues] = useState([])
  const [numberValues, setNumberValues] = useState([])
  const [derivationValues, setDerivationValues] = useState([])
const [viviendaValues, setViviendaValues] = useState([])
  const [validAddress, setValidAddress] = useState(null)
  const [derivationValuesCalled, setDerivationValuesCalled] = useState(false);

  useEffect(() => {
    if (data) {
      console.log(data)
      const savedValues = {
        cp_municipio: {
          cp: data.cp,
          municipio: data.municipio,
        },
        direccion: {
          cp: data.cp,
          tipo_via: data.tipo_via,
          calle: data.calle,
        },
        numero: {
          cp: data.cp,
          tipo_via: data.tipo_via,
          calle: data.calle,
          numero_finca: data.numero_finca,
        },
        vivienda: {
          portal: data.portal,
          escalera: data.escalera,
          piso: data.piso,
          puerta: data.puerta,
        },
        derivation: data.derivation
      }

      if (isIRC) {
        getNumbersDerivaciones(savedValues.direccion).then((data) => {
          setNumberValues(data)
        })
        getDerivations(savedValues.numero).then((data) => {
          setDerivationValues(data)
          setDerivationValuesCalled(true)
        })
      }
      else {
        getNumbers(savedValues.direccion).then((data) => {
          setNumberValues(data)
        })
        getVivienda(savedValues.numero).then((data) => {
          setViviendaValues(data)
        })
      }

      setFormState(savedValues)
    }
  }, []);
  const handleChangeForm = ({ value, name }) => {
    if (value) {
      if (name === 'direccion') {
        if(isIRC) {
          getNumbersDerivaciones(value).then((data) => {
            setNumberValues(data)
          })
        } else {
          getNumbers(value).then((data) => {
            setNumberValues(data)
          })
        }
      }
      if (name === 'numero' && isIRC) {
        setDerivationValuesCalled(false)
        getDerivations(value).then((data) => {
          setDerivationValues(data)
          setDerivationValuesCalled(true)
        })
      } else if(name === 'numero') {
        getVivienda(value).then((data) => {
          setViviendaValues(data)
        })
      }
    } else {
      setFormState({ ...formState, [name]: null })
    }
    const newAddressForm = { ...formState }
    const startIndex = ADDRESS_FORM_FIELDS.indexOf(name)
    ADDRESS_FORM_FIELDS.forEach((f, i) => {
      if (i === startIndex) newAddressForm[f] = value
      else if (i > startIndex) newAddressForm[f] = null
    })
    setTownValues(newAddressForm['cp_municipio'] === null ? [] : [...townValues])
    setAddressValues(newAddressForm['direccion'] === null ? [] : [...addressValues])
    setNumberValues(newAddressForm['direccion'] === null ? [] : [...numberValues])
    setDerivationValues(newAddressForm['numero'] === null ? [] : [...derivationValues])
    setViviendaValues(newAddressForm['numero'] === null ? [] : [...viviendaValues])
    setFormState(newAddressForm)
  }

  useEffect(() => {
    if (derivationValues && derivationValues.length === 1) {
      setFormState({ ...formState, derivation: [derivationValues[0]] })
    }
  } , [derivationValues])

  const getTownValues = (inputValue) =>
    new Promise((resolve, reject) => {
      if(isIRC) {
        getZipMunicipalitiesDerivaciones(inputValue)
          .then((data) => {
            setTownValues(data)
            return resolve()
          })
          .catch(() => reject())
      }
      else {
        getZipMunicipalities(inputValue)
          .then((data) => {
            setTownValues(data)
            return resolve()
          })
          .catch(() => reject())
      }
    })

  const getAddressValues = (inputValue) =>
    new Promise((resolve, reject) => {
      if(isIRC) {
        getStreetsDerivaciones(formState.cp_municipio.cp, inputValue)
          .then((data) => {
            setAddressValues(data)
            return resolve()
          })
          .catch(() => reject())
      }
      else {
        getStreets(formState.cp_municipio.cp, inputValue)
          .then((data) => {
            setAddressValues(data)
            return resolve()
          })
          .catch(() => reject())
      }
    })

  const handleSubmit = () => {
    const address = {
      cups: cups,
      municipio: formState.cp_municipio.municipio,
      cp: formState.cp_municipio.cp,
      provincia: formState.direccion.provincia,
      calle: formState.direccion.calle,
      tipo_via: formState.direccion.tipo_via,
      numero_finca: formState.numero.numero_finca,
      portal: formState.vivienda?.portal,
      escalera: formState.vivienda?.escalera,
      piso: formState.vivienda?.piso,
      puerta: formState.vivienda?.puerta,
    }

    if(isIRC) {
      setValidAddress(true)
      address.derivation = formState['derivation']
      onSelect({ selected: address, close, derivation: formState['derivation'] })
    }
    else {
      validateCupsWithDireccion(address).then((data) => {
        if(data) {
          setValidAddress(true)
          onSelect({ selected: address, close })
        }
        else {
          setValidAddress(false)
        }
      })
        .catch(() => {
          setValidAddress(false)
        })
    }
  }

  const handleCancel = () => {
    if (onCancel)
      onCancel()
    close()
  }

  const inputProps = { formState, onChange: handleChangeForm, intl }
  const fieldsProps = ADDRESS_FIELDS_CONFIG(inputProps)

  return (
    <Grid container spacing={3}>
      {validAddress===false && (<Grid item xs={12}>
        <Box mt={2} mb={1} width={"100%"} bgcolor="common.white">
          <Alert severity="error">{formatMessage({ id: 'pages.inspections.createAddressForm.addressNotMatchCups.feedback' })}</Alert>
        </Box>
      </Grid> )}
      <Grid item xs={12} sm={6}>
        <AutocompleteInput
          {...{ ...fieldsProps.CP_MUNICIPIO, values: townValues, onType: getTownValues }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutocompleteInput
          {...{ ...fieldsProps.DIRECCION, values: addressValues, onType: getAddressValues }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AutocompleteInput {...{ ...fieldsProps.NUMERO, values: numberValues }} />
      </Grid>
      {!isIRC && (
        <Grid item xs={12} sm={9}>
          <AutocompleteInput {...{ ...fieldsProps.VIVIENDA, values: viviendaValues }} />
        </Grid>
      )}
      {isIRC && formState['numero'] && derivationValuesCalled && (
      <Grid item xs={12} sm={9}>
        <AutocompleteInput {...{ ...fieldsProps.DERIVATION, values: derivationValues }} />
      </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-around" mt={2}>
          <ThemeButton color="default" onClick={handleCancel}>
            {formatMessage({ id: 'global.cancel' })}
          </ThemeButton>
          <ThemeButton color="primary" disabled={!formState['derivation'] && !formState['vivienda']} onClick={handleSubmit} loading={isIRC && !derivationValuesCalled && formState['numero']}>
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </Grid>
    </Grid>
  )
}
